import React from 'react';
import { Auth, Hub, Logger } from 'aws-amplify';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Authenticator } from 'aws-amplify-react';

import AppRoute from 'routes/AppRoute.js';
import PublicRoute from 'routes/PublicRoute.js';

import AppLayout from 'layouts/AppLayout.js';
import PublicHomePage from './components/PublicHomePage';

import SignInPage from 'containers/users/SignInPage.js';


import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@fortawesome/fontawesome-free/css/all.css'
import { faArrowUp, faAngleRight } from '@fortawesome/free-solid-svg-icons'

library.add(faArrowUp, faAngleRight)

class App extends React.Component {
     render() {

        let { authState, authData } = this.props;

        let isAuthenticated = (authState === 'signedIn');
        return (
            <Switch>
                <AppRoute 
                    path="/app" 
                    component={AppLayout} 
                    isAuthenticated={isAuthenticated} 
                    {...this.props} />
                <Route exact
                    path="/signin" 
                    component={SignInPage} {...this.props} />
                <PublicRoute exact
                    path="/" 
                    component={PublicHomePage}  
                    isAuthenticated={isAuthenticated} 
                    {...this.props} />
            </Switch>
        );

    }
}



class AppWithAuth extends React.Component {
    render() {
        return (
              <Authenticator hideDefault={true} >
                <App />
              </Authenticator>
        );
    }
}

export default withRouter(AppWithAuth);
