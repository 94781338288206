import Auth from './config/auth.js';
import Analytics from './config/analytics.js';
import API from './config/api.js';
import Interactions from './config/interactions.js';

let config = {
  Auth: Auth,
  API: API,
  Analytics: Analytics,
  Interactions: Interactions
};

export default config;
