import React from 'react';
import Select from 'react-select';
import { CardBody, Row, Col, Card } from 'reactstrap';

import { BotApp } from 'pac-platform-ui-components';

import InsightsMonthlyService from 'services/InsightsMonthlyService';
import TenantManagementService from "services/TenantManagementService";
import QuickSightEmbedUrlService from "services/QuickSightEmbedUrlService";


import { QuicksightData } from 'data/quicksight-data.js';
import { Auth } from 'aws-amplify';
import DropdownDates from 'data/dropdown-dates.js';

import SessionModal from 'components/common/Modal.js';

import TenantSwitch from 'components/common/dashboards/TenantSwitch';


let datesData = null;

let botParameter = {
  botname: "InsightsBot",
  alias: "$LATEST",
  welcome_message: "Ask me about media details",
  requestAttr: {
    "locale": "en-us"
  }
};

let tenantManagementService = new TenantManagementService();

class DashboardBuilder extends React.Component {
 
  constructor(props) {
    super(props);
    let menuItem = props.match.path !== '/app/marketplace' ? 'default' : 'marketplace';
    let defaultDates = DropdownDates.default[0];
    let marketDates = DropdownDates.marketplace[0];

    this.toggle = this.toggle.bind(this);
    this.MonthSelectChangeHandler = this.MonthSelectChangeHandler.bind(this);
    this.state = {
      year: '',
      month: '',
      marketyear: marketDates.year,
      marketmonth: marketDates.value,
      tenantId: null,
      months: DropdownDates[menuItem],
      defaultDate: null,
      dataset: null,
      datesData: null,
      dashboard: null,
      show: true,
      modal: false,
      isMarketplace: menuItem !== 'marketplace' ? false : true,
      selectedValue: null,
      selectedMarketValue: marketDates,
      currentTime: new Date(),
      expiryTime: '', //new Date(date.getTime() + 1*60000),
      counter: 60000,
      subscription: '',
      customer: '',
      userInput: '',
      messages: [],
      inputEnabled: true,
      embedUrlMapping: {}
    };
    
    this.selectRef = React.createRef();
    this.timer = this.timer.bind(this);
  }


  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  timer() {

    let expiryTime = this.state.expiryTime ? this.state.expiryTime : this.expiryTime;

    this.setState({
      currentTime: new Date()
    });

    if (this.state.expiryTime - this.state.currentTime > 1800000) {
      clearInterval(this.interval);
      this.setState({ counter: 900000 });
      this.interval = setInterval(this.timer, 900000);

    }
    else if (this.state.expiryTime - this.state.currentTime < 1020000) {
      clearInterval(this.interval);
      this.setState({ counter: 60000 });
      this.interval = setInterval(this.timer, 60000);

    }
    if (expiryTime && expiryTime - this.state.currentTime <= 0) {
      this.setState({ modal: true });
      clearInterval(this.interval);
    }
  }

  findDefaultVal(label) {
    let ret = null,
      datesObj;

    datesObj = this.state.datesData ? this.state.datesData : datesData;

    datesObj.Items.forEach((t) => {
      if (parseInt(t.default) === 1) {
        ret = t;
        return;
      }
    });
    return ret;
  }

  Append_of_the_month(month, valueToAdd) {
    let __month = valueToAdd ? month + valueToAdd : month;
    __month = __month < 0 ? 11 : __month;
    __month = __month > 11 ? 0 : __month;
    __month += 1; // for formatting so that we can 00 (Jan ) as 01 (Jan)
    return (__month < 10 ? '0' : '') + __month;
  }


  fetchSesssion() {
    let isMarketplace = this.props.match.path === "/app/marketplace";
    let isConnectivity = this.props.match.path === "/app/connectivity";
    var obj;

    Auth
      .currentSession({})
      .then(session => {
        let payload = session.idToken.payload;
        let tenantId = payload.pac_tenant_id;
        let pacIcaoCode = payload.pac_icao_code;
        let cognitoGroups = payload["cognito:groups"];
        let cognitoAssignedSubject = payload.sub;
        let expiryTime = payload.exp ? new Date(payload.exp * 1000) : '';
        let currTenantId = sessionStorage.getItem('pac_selected_tenant_id');
        let currentTenantId = currTenantId ? currTenantId : tenantId;
        this.expiryTime = expiryTime;
        if (!isMarketplace) {
          this.fetchDateRange(tenantId, isConnectivity);
          this.fetchSubscription(currentTenantId, pacIcaoCode, cognitoAssignedSubject, cognitoGroups);
          this.setState({tenantId: tenantId});
        }
        else {
          obj = DropdownDates['marketplace'][0];
          this.setState({ isMarketplace: isMarketplace, tenantId: tenantId, marketmonth: obj.value, marketyear: obj.year, expiryTime });
          this.fetchMarketData(obj.year, obj.value, tenantId);
        }
        this.fetchCustomer(currentTenantId)        
      })
      .catch(err => console.warn(err));
  }

  responseCallback(err, data, botname) {
    console.log(botname);
    console.log(data);
    console.log(err);
  }

  async fetchSubscription(tenantId, pacIcaoCode, cognitoAssignedSubject, cognitoGroups) {
    const creds = await Auth.currentCredentials();

    botParameter.credentialsObject = {
      apiVersion: '2016-11-28',
      region: creds._clientConfig.region,
      accessKeyId: creds.data.Credentials.AccessKeyId,
      secretAccessKey: creds.data.Credentials.SecretKey,
      sessionToken: creds.data.Credentials.SessionToken
    };

    botParameter.sessionAttr = {
      "pac_tenant_id": tenantId,
      "pac_icao_code": pacIcaoCode,
      "cognito_groups": JSON.stringify(cognitoGroups)
    };
    botParameter.userid = cognitoAssignedSubject;

    let response = await tenantManagementService.fetchSubscriptionData(tenantId);
    if (!response) {
      console.log('ERROR');
    }
    else {
      this.setState({
        subscription: response
      });
    }
  }

  async fetchQuickSightEmbededUrl() {
    let pac_tenantId = QuicksightData["pac_tenantId"];
    let dashboardMapping = QuicksightData["mapping"];
    let service = new QuickSightEmbedUrlService();
    for (var i = 0; i < dashboardMapping.length; i++) {
      let dashboardId = dashboardMapping[i]["dashboard_id"];
      let path = dashboardMapping[i]["path"];

      await service
        .fetchUrl(dashboardId)
        .then(response => {
          if (!response.Item) {
            this.setState({ error: "Data Unavailable" });
          }
          this.setState({
            embedUrlMapping: {
               ...this.state.embedUrlMapping,
               [path]: response['embed-url']
            }});
        })
      .catch(error => {
        console.error("onRejected function called: " + error.message);
      });
    }
  }

  async fetchDateRange(tenantId, isConnectivity) {
    let service = new InsightsMonthlyService();

    await service.fetchDateRange(tenantId, isConnectivity).then(response => {
      if (!response) {}
      else {

        datesData = response;
        var obj = this.findDefaultVal();
        var defaultDate = response.Items.filter(function(item, index) {
          return item.default;
        });

        this.setState({
          isMarketplace: false,
          datesData: response,
          tenantId: tenantId,
          month: this.state.month || obj.value,
          year: this.state.year || obj.year,
          defaultDate: this.state.selectedValue || defaultDate
        });
      }
    }).catch(err => console.log(err));
  }

  async fetchCustomer(tenantId) {
    let response = await tenantManagementService.fetchCustomerData(tenantId);

    if (!response) {
      console.log('ERROR');
    }
    else {
      this.setState({
        customer: response
      });
    }
  }

  componentDidMount() {
    this.fetchSesssion();
    this.interval = setInterval(this.timer, this.state.counter);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.path !== prevProps.match.path) {
      this.fetchSesssion();
      let thePath = this.props.match.path;
      let year = this.state.year ? this.state.year : this.state.marketyear;
      let month = this.state.month ? this.state.month : this.state.marketmonth;
      if (thePath.indexOf('marketplace') !== -1) {
        this.setState({ selectedValue: this.state.selectedValue, isMarketplace: true, months: DropdownDates.marketplace, marketmonth: month, marketyear: year });
      }
      else {
        this.setState({ selectedMarketValue: this.state.selectedMarketValue, isMarketplace: false, months: DropdownDates.default, month, year });
      }
    }
  }


  MonthSelectChangeHandler(selectedOption) {
    if (typeof selectedOption.value !== 'undefined') {

      let thisMonth = selectedOption.value;
      let thisYear = selectedOption.year;

      if (this.state.isMarketplace) {
        this.setState({ selectedMarketValue: selectedOption, marketmonth: thisMonth, marketyear: thisYear });
        this.fetchMarketData(thisYear, thisMonth, this.state.tenantId);
      }
      else {
        this.setState({ defaultDate: selectedOption, selectedValue: selectedOption, month: thisMonth, year: thisYear });
        // this.fetchData(thisYear, thisMonth, this.state.tenantId);
      }
    }
  }

  changehandler() {
    this.setState({ show: true });
  }

  getMainTitle() {
    console.debug(this.props.match);
    
    if (this.props) {
      if (this.props.match.path === "/app/premium-dashboard/qoe") {
        return "Quality of Experience";
      } else if (this.props.match.path === "/app/premium-dashboard/ccr") {
        return "PAXUS Reports";
      }
    }

  }

  getDashboardComponent() {

    let { month, year, dataset, tenantId, marketmonth, marketyear } = this.state;

    if (this.state.error) {
      return (<div>
              <Card>
               <CardBody>
               <h3>Data Unavailable</h3>
               </CardBody>
             </Card>
             </div>);
    }

  }

  getDateController() {

    let { months, selectedValue, selectedMarketValue } = this.state;

    let dateSet = "default";
    let isMarketplace = false;

    if (this.props) {
      if (this.props.match.path === "/app/marketplace") {
        dateSet = "marketplace";
        isMarketplace = true;
      }
    }

    selectedValue = this.state.datesData ? this.state.defaultDate : selectedValue;

    let datesValue = this.state.datesData ? this.state.datesData.Items : months;
    months = this.props.match.path !== '/app/marketplace' ? datesValue : months;

    return (
      <React.Fragment>
              <Col className="selector-label">Date Range</Col>
              <Col>
                <Select className="selectBox"
                  style={{fontSize:"14px"}}
                  value={!isMarketplace ? selectedValue : selectedMarketValue}
                  name="month"
                  options={months}
                  className="basic-multi-select month-selector"
                  classNamePrefix="select" 
                  onChange={this.MonthSelectChangeHandler}
                  ref={this.selectRef}
                  isDisabled={false}
                  isSearchable= {false}
                />
              </Col>
              </React.Fragment>);

  }

  getChatbotComponent() {
    // The chat bot should be shown only if the tenant is a premium member
    if (this.state.subscription["subscription-level"] === "Premium") {
      return <BotApp {...botParameter}/>;
    }
    return "";
  }

  getQuickSightUrl() {
    return this.state.embedUrlMapping[this.props.location.pathname];
  }

  getQuickSightComponent() {
    if (this.state.subscription["subscription-level"] === "Premium") {
      // If the variable: embedUrlMappping is '{}', then we fetch the embed URLs again
      if (Object.keys(this.state.embedUrlMapping).length === 0) {
        //This is an async call
        this.fetchQuickSightEmbededUrl();
      }
      let quickSightUrl = this.getQuickSightUrl();
      // The quicksight dashboards should be shown only if the tenant is a premium member
      //if (quickSightUrl !== undefined && this.state.subscription["subscription-level"] === "Premium") {
      if (quickSightUrl !== undefined) {
        let iframeAttributes = {
          "overflow-y": "auto",
          "frameborder": "0",
          "height": "100%",
          "width": "100%",
          "id": "quickSightDashboard",
          "title": "dashboard",
          "src": quickSightUrl
        };
        return <iframe {...iframeAttributes}/>;
      }
    }
    return "";
  }

  quickSightDashboardClicked() {
    return (this.props.location.pathname === "/app/premium-dashboard/qoe" 
            || this.props.location.pathname === "/app/premium-dashboard/ccr" );
  }

  render() {
    let subtitle = "";
    const maintitle = this.getMainTitle();
    const sessionTitle = 'Session Expired';
    const sessionMsg = 'Your Session has expired. Please refresh the page.';

    let dashboard = this.getDashboardComponent();
    let chatbot = this.getChatbotComponent();
    let dateController = this.getDateController();

    if (this.quickSightDashboardClicked()) {
      let quickSightDashboard = this.getQuickSightComponent();
        return(
            <div>
              {this.state.modal ? (<SessionModal modal='true' title={sessionTitle} message={sessionMsg}/>) : ''}
              <Row className="header">
                <Col md="6" className="page-title">
                  {maintitle}
                </Col>
                <Col md="6">
                </Col>
              </Row>
              <Row>
                <Col md="12" className="quick-sight">
                  <div style={{"height":"1000px"}}>
                    {quickSightDashboard}
                  </div>
                </Col>
              </Row>
            </div>
        );
    } else {
        return (
          <div>
            {this.state.modal ? (<SessionModal modal='true' title={sessionTitle} message={sessionMsg}/>) : ''}
            
              <Row className="header">
    
                <Col md="4" className="page-title">
                  {maintitle}
                </Col>
    
                <Col className="md-8">
                      <div className="selector-row d-flex">
                            <div className="d-flex date-selector">{dateController}</div>
    
                              {/*
                              */}
                                <div className="d-inline ml-2" style={{"width":"200px"}}>
                                    <TenantSwitch />
                                </div>

                      </div>      
                </Col>
              
              </Row>
    
              
            {
              subtitle.length > 0 ? 
                (<Row>
                  <Col>
                    <div className="Movie-subtitle">{subtitle}</div>
                  </Col>
                </Row>) : ''
            }
            {dashboard}
              <Row>
                <Col>
                {chatbot}
                </Col>
              </Row>
          </div>
      );
    }
  }
}

export default DashboardBuilder;
