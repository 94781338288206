import { Auth } from 'aws-amplify';

const productionTenantManagementServiceAPIEndpoint = 'https://api.console.nextcloud.aero/v0';


function TEMPORARY_USE_ONLY_getTenantManagementServiceAPIEndpoint() {
    return 'https://uhdg0ahs3d.execute-api.us-west-2.amazonaws.com/dev';
}


function getTenantManagementServiceAPIKey() {
    switch (process.env.REACT_APP_STAGE) {
        case 'prd':
            return 'api-key-prd';
        case 'test':
            return 'api-key-test';
        case 'dev':
            return 'api-key-dev';
        case 'cloud9':
            return 'api-key-cloud9';
        default:
            throw "Invalid Stage Environment";
    }
}

function getQuickSightEmbedUrlServiceAPIEndpoint() {
    switch (process.env.REACT_APP_STAGE) {
        case 'prd':
        case 'test':
        case 'dev':
        case 'cloud9':
            return 'https://tvc6dzqkg5.execute-api.us-west-2.amazonaws.com/dev/';
            //return 'https://ykgllazd1j.execute-api.us-west-2.amazonaws.com/dev/'
        default:
            throw "Invalid Stage Environment";
    }
}


let applicationApiKey = [process.env.REACT_APP_NAME, getTenantManagementServiceAPIKey()].join('-');

async function getAuthorizationHeader() {
    const currentSession = await Auth.currentSession();
    return currentSession.idToken.jwtToken;
}



let API = {
    endpoints: [{
            name: process.env.REACT_APP_INSIGHTS_API_NAME,
            endpoint: process.env.REACT_APP_INSIGHTS_API_URL,
            region: process.env.REACT_APP_INSIGHTS_API_REGION,
            custom_header: async() => {
                return {
                    Authorization: await getAuthorizationHeader()
                };
            }
        }, {
            name: 'TenantManagementServiceAPI',
            endpoint: TEMPORARY_USE_ONLY_getTenantManagementServiceAPIEndpoint(),
            region: 'us-west-2',
            custom_header: async() => {
                return {
                    Authorization: await getAuthorizationHeader(),
                    'x-api-key': applicationApiKey
                };
            }
        }, {
            name: 'QuickSightEmbedUrlServiceAPI',
            endpoint: getQuickSightEmbedUrlServiceAPIEndpoint(),
            region: 'us-west-2',
            custom_header: async() => {
                return {
                    Authorization: await getAuthorizationHeader()
                };
            }
        }
    ]
};

export default API;
