import React from 'react'
import { Route, Redirect } from 'react-router-dom';

class AppRoute extends React.Component {


render() {
  const { component: Component, isAuthenticated, match, ...rest } = this.props;
    let redirectPathname = '/';
if(this.props.location.pathname!=undefined){
  redirectPathname=this.props.location.pathname;
} 


  return (
    <Route
        {...rest}
        render={props => {return (isAuthenticated) ? (<Component {...props} />): (<Redirect to={{pathname:redirectPathname}} />);
}
}
/>
);

}


}

export default AppRoute;
