import React, { Component } from 'react';

// import {
//     Dropdown,
//     DropdownToggle,
//     DropdownMenu,
//     DropdownItem
// }
// from 'reactstrap';

import { Dropdown } from 'pac-ux-components';

import { Auth, Logger } from 'aws-amplify';

import UsersService from 'services/UsersService';

import { withRouter } from 'react-router-dom';

import TenantSwitchComponent from 'components/common/dashboards/TenantSwitchComponent';


const logger = new Logger('TenantSwitch');

const service = new UsersService();

const qualityManagementGroupName = 'quality_management';

const TENANT_OPTIONS_SESSION_KEY = 'pac_tenants';
const SELECTED_TENANT_ID_SESSION_KEY = 'pac_selected_tenant_id';
const SELECTED_TENANT_SESSION_KEY = 'pac_selected_tenant';
const DEFAULT_TENANT_NAME = 'PanaSky';


export class TenantSwitch extends Component {

    constructor(props) {
        super(props);

        // let options = JSON.parse(window.sessionStorage.getItem(TENANT_OPTIONS_SESSION_KEY));

        this.state = {
            options: [],
            selected: null,
            showCustomersDropdown: false,
            dropdownOpen: false,
        };

    }


    componentDidMount() {

        Auth.currentSession()
            .then((currentSession) => {

                if (this.currentUserBelongsToQualityManagementGroup(currentSession)) {
                    this.setState({ showCustomersDropdown: true });
                }

                let options = JSON.parse(window.sessionStorage.getItem(TENANT_OPTIONS_SESSION_KEY));

                if (options === null) {

                    let username = currentSession.idToken.payload["cognito:username"];

                    service
                        .fetchUserTenants(username)
                        .then(results => {

                            if (typeof results !== 'undefined') {

                                options = results.Items.map(item => {
                                    // return { label: item.name, value: { id: item.id } };
                                    return { label: item.name, value: item.id };
                                });

                                this.setState({ options: options });
                                window.sessionStorage.setItem(TENANT_OPTIONS_SESSION_KEY, JSON.stringify(options));

                            }

                        });
                }
                else {
                    this.setState({ options: options });
                }



                let selectedTenant = JSON.parse(window.sessionStorage.getItem(SELECTED_TENANT_SESSION_KEY));
                console.debug('>>>', selectedTenant);

                if (selectedTenant) {
                    this.setState({ selected: selectedTenant });
                }
                else {

                    selectedTenant = options.filter(option => {
                        return option.label.toString() === DEFAULT_TENANT_NAME;
                    }).shift();

                    window.sessionStorage.setItem(SELECTED_TENANT_SESSION_KEY, JSON.stringify(selectedTenant));
                    window.sessionStorage.setItem(SELECTED_TENANT_ID_SESSION_KEY, selectedTenant.value);

                    this.setState({ selected: selectedTenant });
                }




            })
            .catch((err) => {
                logger.error(err);
            });


    }

    toggle = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }


    currentUserBelongsToGroup = (currentSession, groupName) => {
        const groups = currentSession.idToken.payload["cognito:groups"];
        if (typeof groups !== 'undefined') {
            for (let i = 0; i < groups.length; i++) {
                if (groups[i] === groupName) {
                    return true;
                }
            }
        }
        return false;
    }

    currentUserBelongsToQualityManagementGroup = (currentSession) => {
        return this.currentUserBelongsToGroup(currentSession, qualityManagementGroupName);
    }


    handleTenantChange = (customerId) => {
        let { history } = this.props;
        
        let selectedTenant = this.getCustomerById(customerId);

        window.sessionStorage.setItem(SELECTED_TENANT_SESSION_KEY, JSON.stringify(selectedTenant));
        window.sessionStorage.setItem(SELECTED_TENANT_ID_SESSION_KEY, customerId);

        this.setState({ selected: selectedTenant });

        history.push('/')
    }

    getCustomerById = (customerId) => {
        let { options } = this.state;

        if (options) {
            return options.filter(option => {
                return option.value === customerId;
            }).shift();
        }

    }

    getCustomerByName = (customerName) => {
        let { options } = this.state;

        if (options) {
            return options.filter(option => {
                return option.label === customerName;
            }).shift();
        }

    }


    render() {

        let { options, selected, showCustomersDropdown } = this.state;

        if (!showCustomersDropdown) {
            return null;
        }

        // console.debug('OPTIONS >>>> ', options);
        // console.debug('SELECTED >>>> ', selected);

        if (options === []) {
            return (<div>no options</div>)
        }
        
        return (<TenantSwitchComponent dropdownOptions={options} dropdownSelected={selected} handleTenantChange={this.handleTenantChange} />);

    }



}

export default withRouter(TenantSwitch);
