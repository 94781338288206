import React, { Component } from 'react';

import { Dropdown } from 'pac-ux-components';

const TenantSwitchComponent = (props) => {

    let { dropdownOptions, dropdownSelected, handleTenantChange } = props;

    return (<Dropdown 
                    disabled={false}
                    //filterLabel="Find item"
                    label="Select Customer"
                    onChange={handleTenantChange} 
                    //onFilter={this.updateStorage}        
                    options={dropdownOptions} 
                    //renderSelectedLabel={}
                    selected={dropdownSelected}
                    size="default"
                    tabIndex={0} />);


}

export default TenantSwitchComponent;
