import React from 'react';

import {
    ToolMenu,
    ToolMenuItem,
    ToolMenuChild,
    IconHome24,
    IconReport24
}
from 'pac-ux-components';

import menus from 'data/menus.js';
import TenantManagementService from "services/TenantManagementService";

import * as labels from 'constants/labels';
import { I18n } from 'aws-amplify';
import { Auth } from 'aws-amplify';


let tenantManagementService = new TenantManagementService();


class PageToolsSidebar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentMenuItem: null,
            currentMenuItemChild: null,
            isClosed: false,
            tenantId: null,
            subscription: ''
        };

        this.handleClosed = this.handleClosed.bind(this);
        this.handleSidebar = this.handleSidebar.bind(this);

    }

    componentDidMount() {
        this.handleSidebar();
        window.addEventListener('resize', function() {
            this.handleSidebar();
        }.bind(this));
        this.fetchSesssion();
    }

    renderMenuItem(menuItem, menuItemIndex) {
        let isPremiumItem = this.isPremiumItem(menuItem);
        let isPremiumUser = this.isPremiumUser();
        let { currentMenuItem, currentMenuItemChild } = this.state;
        let { history } = this.props;

        let iconComponent = (menuItem.title === 'Home') ? <IconHome24 /> : <IconReport24 />;

        if (menuItem.icon) {
            iconComponent = menuItem.icon;
        }

        let self = this;
        let showItem = isPremiumItem ? isPremiumUser : true;
        if(showItem) {
            if (menuItem.children.length > 0) {
                let isActive = false;
                if (currentMenuItem !== 'undefined') {
                    if (currentMenuItem !== null) {
                        isActive = (currentMenuItem.path === menuItem.path);
                    }
                }
                isActive = isPremiumItem ? isPremiumUser : false;
                return (
    
                    <ToolMenuItem
                          key={menuItemIndex}
                          as="div"
                          href={menuItem.path}
                          title={I18n.get(menuItem.title)}
                          icon={iconComponent}
                          onClick={(event) => {
                            self.setState({currentMenuItem:menuItem});
                            return true;
                          }}
                          isActive={isActive}
                        >
    
                        {menuItem.children.map(function(item, index) {
                        
                            let isActiveChild = false;
                            if (currentMenuItem !== 'undefined') {
                                if (currentMenuItem !== null) {
                                    if (currentMenuItem.children.length > 0) {
                                        if (currentMenuItemChild !== 'undefined') {
                                            if (currentMenuItemChild !== null) {
                                                isActiveChild = (currentMenuItemChild.path === item.path);
                                            }
                                        }
                                    }
                                }
                            }
                        
                        
                          return (
                                <ToolMenuChild
                                      key={index}
                                      as="div"
                                      href={item.path}
                                      title={I18n.get(item.title)}
                                      onClick={(event) => {
                                        self.setState({currentMenuItemChild:item});
                                        history.push(item.path);
                                        return false;
                                      }}
                                      isActive={isActiveChild}
                                    />);
                        })}
                            
    
                    </ToolMenuItem>
                );

            }
            else {
                let isActive = false;
                if (currentMenuItem !== 'undefined') {
                    if (currentMenuItem !== null) {
                        isActive = (currentMenuItem.path === menuItem.path);
                    }
                }
                
                return (
                    <ToolMenuItem
                      key = {menuItemIndex}
                      as="div"
                      href={menuItem.path}
                      title={I18n.get(menuItem.title)}
                      icon={iconComponent}
                      className="cursor-pointer"
                      onClick={(event) => {
                        self.setState({currentMenuItem:menuItem});
                        history.push(menuItem.path);
                        return false;
                      }}
                      isActive={isActive}
                    />);
            }
        }

    }

    isPremiumItem(menuItem) {
        return ((menuItem.premiumItem !== 'undefined' && menuItem.premiumItem) ? true : false);
    }

    isPremiumUser() {
        return this.state.subscription["subscription-level"] === "Premium" ? true : false;
    }

    handleClosed() {

        let { isClosed } = this.state;

        this.setState({
            isClosed: !isClosed
        });

        return !isClosed;
    }


    handleSidebar() {
        if (window.innerWidth < 1400) {
            this.setState({
                isClosed: true
            })
        }
        else {
            this.setState({
                isClosed: false
            })
        }
    }

    render() {

        let { isClosed } = this.state;

        let activeMenuItems = menus['sidebar'].filter(menuItem => menuItem.isActive === true);

        return (
            <div className={this.state.isClosed ? "side-bar collapsed" : "side-bar"}>
            <ToolMenu 
                title={labels.NEXT_CLOUD_QOE} 
                isClosed={isClosed} 
                handleClosed={this.handleClosed}>
                
              {activeMenuItems.map((menuItem, menuItemIndex) => {
                return this.renderMenuItem(menuItem, menuItemIndex);    
              })}
                
            </ToolMenu>
            </div>
        );
    }

    fetchSesssion() {
        Auth
          .currentSession({})
          .then(session => {
            let payload = session.idToken.payload;
            let tenantId = payload.pac_tenant_id;
            let currTenantId = sessionStorage.getItem('pac_selected_tenant_id');
            let currentTenantId = currTenantId ? currTenantId : tenantId;
            this.fetchSubscription(currentTenantId);
            
          })
          .catch(err => console.warn(err));
      }

    async fetchSubscription(tenantId) {
        let response = await tenantManagementService.fetchSubscriptionData(tenantId);
        if (!response) {
          console.log('ERROR');
        }
        else {
          this.setState({
            subscription: response
          });
        }
      }
}

export default PageToolsSidebar;
