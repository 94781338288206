import BaseService from 'services/BaseService';

class TenantManagementService extends BaseService {


  constructor() {
    let apiName = 'TenantManagementServiceAPI';
    let collectionPath = '/tenants';
    super(apiName, collectionPath);
  }

  async fetchSubscriptionData(tenantId) {
    
    let path = [
      this.collectionPath,
      tenantId,
      'apps',
      process.env.REACT_APP_ID
    ].join('/');
    
    let options = {};
    
    return this.makeGetApiCall(this.apiName, path, options);
  }

  async fetchCustomerData(tenantId) {
    
    let path = [
      this.collectionPath,
      tenantId
    ].join('/');
    
    let options = {};
    
    return this.makeGetApiCall(this.apiName, path, options);
  }

}

export default TenantManagementService;
