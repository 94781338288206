import React from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom';

class PublicRoute extends React.Component {

  render() {
 console.log("Public page route");
console.log(this.props);
    const { component: Component, isAuthenticated, match, ...rest } = this.props;
     let redirectPathname = '/app/premium-dashboard/ccr';

    return (
      <Route
        {...rest}
        render={props => {
            return (isAuthenticated) ? (<Redirect to={{pathname:redirectPathname}} />): (<Component isAuthenticated={isAuthenticated} {...props} />);
  }
}
/>);

}


}

export default withRouter(PublicRoute);
