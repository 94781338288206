const oauth = {
  // Domain name
  domain: process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN,

  // Authorized scopes
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],

  // Callback URL
  redirectSignIn: process.env.REACT_APP_HOST,

  // Sign out URL
  redirectSignOut: process.env.REACT_APP_SIGNOUT_URL,

  // 'code' for Authorization code grant, 
  // 'token' for Implicit grant
  responseType: 'token',

  // optional, for Cognito hosted ui specified options
  options: {
    // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
    AdvancedSecurityDataCollectionFlag: true
  }
};


let Auth = {
  // REQUIRED - Amazon Cognito Identity Pool ID
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,

  // REQUIRED - Amazon Cognito Region
  region: process.env.REACT_APP_COGNITO_REGION,

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,

  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: false,
  //cookieStorage: cookieStorage,
  oauth: oauth
};

export default Auth;
