import Amplify, { Analytics } from 'aws-amplify';


//import JwtHelper from 'util/JwtHelper';

import {JwtHelper} from 'pac-platform-ui-auth';


// get config
import config from './config.js';
import LinkHelper from 'util/LinkHelper';



const helper = new JwtHelper();
let decodedToken = helper.run();
const linkhelper = new LinkHelper();

linkhelper.addTenantIdUrlFragment();
let testtenantid = linkhelper.getCurAuthTenandid()

if (typeof decodedToken !== 'undefined') {
    config.Auth.userPoolId = decodedToken.pac_user_pool_id;
    config.Auth.userPoolWebClientId = decodedToken.pac_app_client_id;
    config.Auth.oauth.domain = decodedToken.pac_user_pool_domain + '.auth.us-west-2.amazoncognito.com';

    Object.assign(config.Analytics.AWSPinpoint.endpoint.userAttributes, {
        CognitoUsername: [decodedToken['cognito:username']],
        Email: [decodedToken.email],
        AppClientId: [decodedToken.pac_app_client_id],
        FirstName: [decodedToken.pac_first_name],
        IcaoCode: [decodedToken.pac_icao_code],
        LastName: [decodedToken.pac_last_name],
        TenantId: [decodedToken.pac_tenant_id],
        TenantName: [decodedToken.pac_tenant_name],
        UserPoolDomain: [decodedToken.pac_user_pool_domain],
        UserPoolId: [decodedToken.pac_user_pool_id],
        Sub: [decodedToken.sub]
    });


}

if (typeof config.Auth.userPoolId !== 'undefined') {
    Amplify.configure(config);
}

switch (process.env.NODE_ENV) {
    case 'development':
        Amplify.Logger.LOG_LEVEL = 'DEBUG';
        //window.LOG_LEVEL = 'DEBUG';
        break;
    case 'production':
        Amplify.Logger.LOG_LEVEL = 'INFO';
        break;
}


Analytics.autoTrack('pageView', {
    // REQUIRED, turn on/off the auto tracking
    enable: true,
    // OPTIONAL, the event name, by default is 'pageView'
    eventName: 'pageView',
    // OPTIONAL, the attributes of the event, you can either pass an object or a function 
    // which allows you to define dynamic attributes
    // attributes: {
    //     attr: 'attr'
    // },
    // when using function
    // attributes: () => {
    //    const attr = somewhere();
    //    return {
    //        myAttr: attr
    //    }
    // },
    // OPTIONAL, by default is 'multiPageApp'
    // you need to change it to 'SPA' if your app is a single-page app like React
    type: 'SPA',
    // OPTIONAL, the service provider, by default is the AWS Pinpoint
    provider: 'AWSPinpoint',
    // OPTIONAL, to get the current page url
    getUrl: () => {
        // the default function
        return window.location.origin + window.location.pathname;
    }
});


Analytics.autoTrack('event', {
    // REQUIRED, turn on/off the auto tracking
    enable: true,
    // OPTIONAL, events you want to track, by default is 'click'
    events: ['click'],
    // OPTIONAL, the prefix of the selectors, by default is 'data-amplify-analytics-'
    // in order to avoid collision with the user agent, according to https://www.w3schools.com/tags/att_global_data.asp
    // always put 'data' as the first prefix
    selectorPrefix: 'data-amplify-analytics-',
    // OPTIONAL, the service provider, by default is the AWS Pinpoint
    provider: 'AWSPinpoint',
    // OPTIONAL, the default attributes of the event, you can either pass an object or a function
    // which allows you to define dynamic attributes
    attributes: {
        attr: 'attr'
    }
});
