import React from 'react';
import { IconDiagram20 } from 'pac-ux-components';

import { ReactComponent as Feedback } from 'assets/images/icons/sidebar/Feedback.svg';


const menus = {
    sidebar: [{
            path: "/app/web-portal",
            title: "Dashboards",
            icon: (<IconDiagram20 />),
            isActive: true,
            children: [
                { path: "/app/premium-dashboard/ccr", title: "PAXUS Reports", isActive: true, children: [] }
            ]
        }

    ]
};


export default menus;
