import React from 'react';
import {Route, Switch} from 'react-router-dom';

import DashboardBuilder from 'components/common/dashboards/DashboardBuilder';


const WebPortalLayout = ({match}) => (
        <Switch>
            <Route  
                path={`${match.path}/qoe`} 
                component={DashboardBuilder} /> 
            <Route  
                path={`${match.path}/ccr`} 
                component={DashboardBuilder} /> 
        </Switch>
);


export default WebPortalLayout;