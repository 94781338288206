import React from 'react';
import { Row, Col } from 'reactstrap';

import Feedback from 'components/feedback/Feedback';


class FeedbackPage extends React.Component {


    render() {

        return (
            <div>
                <Row className="header">
                    <Col md="4" className="page-title">
                        Feedback
                    </Col>
                </Row>
                <Row>
                        <Feedback />
                </Row>
            </div>
        );
    }
}

export default FeedbackPage;
