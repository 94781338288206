import React from 'react';
import StarRatingComponent from './StarRatingComponent';
import InsightsMonthlyService from 'services/InsightsMonthlyService';
import { Modal, Container, ModalBody, Button } from 'reactstrap';



class Feedback extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rating: 0,
            rating_custom_icon: 6,
            rating_half_star: 3.5,
            rating_empty_initial: 0,
            feedbackinput: "",
            showPopup: false,
            isOpen: false,
            modal1: false,
            modal2: false,
            feedbackenable: false,
            clearenable: false
        };

        this.showModal = this.showModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClear = this.handleClear.bind(this);

    }

    closeModal(tabId) {
        this.setState({
            [tabId]: false
        });
    }
    showModal(modal) {
        if (modal === "modal1") {
            this.setState({
                [modal]: true
            });
        }
        if (modal === "modal2") {
            let payload = {
                "rating": this.state.rating.toString(),
                "feedback": this.state.feedbackinput
            }
            this.PostData(payload);
        }
        console.log(this.state);
    }
    toggleModal = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    onStarClick(nextValue, prevValue, name) {
        console.log('name: %s, nextValue: %s, prevValue: %s', name, nextValue, prevValue);
        this.setState({ rating: nextValue, feedbackenable: true, clearenable: true });
    }
    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }
    onStarClickCustomIcon(nextValue, prevValue, name) {
        console.log('name: %s, nextValue: %s, prevValue: %s', name, nextValue, prevValue);
        this.setState({ rating_custom_icon: nextValue });
    }

    onStarClickHalfStar(nextValue, prevValue, name, e) {
        const xPos = (e.pageX - e.currentTarget.getBoundingClientRect().left) / e.currentTarget.offsetWidth;

        if (xPos <= 0.5) {
            nextValue -= 0.5;
        }

        console.log('name: %s, nextValue: %s, prevValue: %s', name, nextValue, prevValue);
        // console.log(e); 
        this.setState({ rating_half_star: nextValue });
    }

    onStarClickEmptyInitial(nextValue, prevValue, name) {
        console.log('name: %s, nextValue: %s, prevValue: %s', name, nextValue, prevValue);
        this.setState({ rating_empty_initial: nextValue });
    }



    async PostData(payload) {
        let service = new InsightsMonthlyService();
        let error = "";
        await service
            .addfeedback(payload)
            .then(response => {
                if (response.status === "200") {
                    this.setState({
                        modal2: true,
                        modal1: false,
                        rating: 0,
                        feedbackinput: '',
                        feedbackenable: false,
                        clearenable: false
                    })

                }
                else {
                    this.setState({ error: true })
                }

            }).catch(error => {

                console.error('onRejected function called: ' + error.message);
            });;

    }
    handleChange(event) {
        if (!event.target.value && this.state.rating === 0) {
            this.setState({ feedbackinput: "", feedbackenable: false, clearenable: false });
        }
        else {
            this.setState({ feedbackinput: event.target.value, feedbackenable: true, clearenable: true });
        }
    }
    handleClear(event) {
        this.setState({ rating: 0, feedbackinput: "", feedbackenable: false, clearenable: false });

    }
    render() {

        return (
            <React.Fragment> 
       
          <div   className={!this.state.showPopup?'feedback-card':'feedback-card-pop'} > 
            <div className={'feedback-headertext'}> Thanks for using Insights.</div> 
            <div className={'feedback-secondheadertext'}>How are we doing? Please Rate your experience</div> 
 
                <div style={{fontSize: 47}} className={'feedback-stars'}> 
           <StarRatingComponent 
            name="app2" 
            starCount={5} 
            value={this.state.rating} 
            onStarClick={this.onStarClick.bind(this)} /> 
        </div> 
        <div  className={'feedback-textarea'} > 
 <textarea cols={97} rows={12} placeholder="Suggestions, features to include, etc" value={this.state.feedbackinput} name="feedbackinput" onChange={this.handleChange}></textarea> 
       </div> 
       <div className={'feedback-submit-div'}> 
  
       <Button  disabled={!this.state.clearenable}  className={'feedback-clear'} onClick={this.handleClear}> 
        Clear 
        </Button> 
           <Button disabled={!this.state.feedbackenable} className={'feedback-submit'} onClick={this.showModal.bind(this, 'modal1')}> 
          Share Feedback 
        </Button> 
 </div>  
        <Container> 
         <Modal isOpen={this.state.modal1} toggle={this.closeModal.bind(this, 'modal1')} className={'modal-first'}> 
            
           <ModalBody className={'popup-modal'}  > 
           <div className={'modal-pop-submit-feedback-text1'}> 
           Submit Feedback 
           </div> 
           <div className={'modal-pop-submit-feedback-text2'}> 
           Do you want to submit this form? 
           </div> 
           <div className={'modal-first-body'}> 
             <Button className={'popup-clear'}  onClick={this.closeModal.bind(this, 'modal1')}> 
              Cancel 
             </Button> 
                   <Button className={'popup-submit'} onClick={this.showModal.bind(this, 'modal2')}> 
              Submit 
             </Button> 
             </div> 
           </ModalBody> 
        </Modal> 
        <Modal isOpen={this.state.modal2} toggle={this.closeModal.bind(this, 'modal2')}> 
           
           <ModalBody className={'popup-modal'} > 
             <div className={'modal-pop-submit-feedback-text1'}> 
           Thanks 
           </div> 
           <div className={'modal-pop-submit-feedback-text2'}> 
           Your feedback has been successfully submitted 
           </div> 
            <div className={'modal-first-body'}> 
             <Button className={'popup-submit'}  onClick={this.closeModal.bind(this, 'modal2')}> 
              Ok 
             </Button> 
             
             </div> 
           </ModalBody> 
        </Modal> 
        </Container> 
        
  
              </div> 
               </React.Fragment>
        )
    }
}

export default Feedback;
