import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PageSidebar from 'components/common/PageToolsSidebar';
import SettingsPage from 'containers/users/SettingsPage';
import FeedbackPage from 'components/feedback/FeedbackPage.js';
import PremiumDashboardLayout from 'layouts/PremiumDashboardLayout';

import {
    UserProfilePage,
    PlatformCloudbar,
    PlatformFooter
}
from 'pac-platform-ui-components';
import { Auth } from 'aws-amplify';



class DashboardsLayout extends React.Component {

    constructor(props) {
        super(props);
        this.monthSelectChangeHandler = this.monthSelectChangeHandler.bind(this);
        this.changeTheme = this.changeTheme.bind(this);

        let theme = window.localStorage.getItem('theme');
        if (typeof theme === 'undefined' || 'null') {
            theme = 'light';
            window.localStorage.setItem('theme', theme);
        }


        this.state = {
            month: null,
            year: 2018,
            theme: theme,
            user: {}
        };

    }


    monthSelectChangeHandler(field, value) {
        this.setstate = {
            month: field,
            year: value,
            dashboards1: this.state.dashboards1
        };
    }

    changeTheme(event) {

        let { theme } = this.state;

        let newTheme = (theme === 'light') ? 'dark' : 'light';

        window.localStorage.setItem('theme', newTheme);

        this.setState({
            theme: newTheme
        });
    }
    componentDidMount() {

        Auth
            .currentUserInfo({})
            .then(user => {

                this.setState({
                    user: user
                    //subscriptions: JSON.parse(payload.pac_tenant_subscriptions)
                });

            })
            .catch(err => console.log(err));

    }


    componentDidUpdate(prevProps, prevState) {

    }


    renderRoutes() {
        let { match } = this.props;
        let { theme } = this.state;

        return (
            <Switch>
                <Route  
                    path={`${match.path}/feedback`} 
                    component={FeedbackPage} {...this.props}/>
                <Route  
                    path={`${match.path}/premium-dashboard`} 
                    component={PremiumDashboardLayout} {...this.props}/> 

                <Route  
                    path={`${match.path}/profile`} 
                    render={props=>{
                            return ( <UserProfilePage {...this.props} user={this.state.user} />)
                        }
                    }
                    />
                <Route 
                    path={`${match.path}/settings`}
                    render={props =>{
                        return (<SettingsPage {...this.props} theme={theme} changeThemeHanlder={this.changeTheme} />);
                    }}
                    />    
            </Switch>
        );
    }

    render() {
        let { match } = this.props;
        let { theme } = this.state;
        let wrapperClass = "container-fluid h-100 main-container " + theme;

        const cloudHomeUrl = process.env.REACT_APP_CLOUD_HOME_URL ? process.env.REACT_APP_CLOUD_HOME_URL : '/';

        return (
            <React.Fragment>

                    <PlatformCloudbar  cloudHomeUrl={cloudHomeUrl} />

                    {/*
                    <Route path={`${match.path}`} component={SharedCloudMenu} {...this.props}  />
                    */}
                    

                    <div className="main-container">
                        
                        <Route path={`${match.path}`} component={PageSidebar} {...this.props}  />
    
                        <main className="main-content">
                            {this.renderRoutes()}
                        </main>
                    </div>
        
                    <div className="pac-footer-wrapper">
                        <div className="pac-footer">
                            <PlatformFooter />
                        </div>
                    </div>

            </React.Fragment>
            
        );
    }
}

export default DashboardsLayout;
