import React from 'react';
import ReactDOM from 'react-dom';
import Popper from 'popper.js';

import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css'

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import './assets/sass/index.scss';

import './assets/sass/entertainment.scss';
import './assets/sass/updatedComponents/carousel.css';
import './assets/sass/updatedComponents/coverage.css';
import './assets/sass/updatedComponents/horizontalBarChart.css';
import './assets/sass/updatedComponents/widget.css';
import './assets/sass/updatedComponents/dropDown.css';
import './assets/sass/updatedComponents/donut.css';


import './assets/sass/layout.scss';


import Modal from 'react-bootstrap/Modal';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import AmpConfig from './amplify-configure.js';

import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter as Router } from 'react-router-dom';

ReactDOM.render(<Router><App /></Router>, document.getElementById('root'));
registerServiceWorker();

