import { Auth, API, Logger } from 'aws-amplify'; 
 
const logger = new Logger('BaseService') 
 
class BaseService { 
 
    constructor(apiName, collectionPath) { 
 
        this.apiName = apiName; 
        this.collectionPath = collectionPath; 
 
    } 
 
    async getAuthorizationHeader() { 
        const currentSession = await Auth.currentSession(); 
        return currentSession.idToken.jwtToken; 
    } 
 
    async fetchRecords(query, callback) { 
 
        let path = this.collectionPath; 
 
        let headers = { 
            Authorization: await this.getAuthorizationHeader() 
        }; 
 
        let queryStringParameters = { start: 0, limit: 3 }; 
        // overwrite 
        queryStringParameters = query; 
 
        let options = { headers: headers, queryStringParameters: queryStringParameters }; 
 
        this.makeGetApiCall(this.apiName, path, options).then(callback); 
 
    } 
 
    async fetch(recordId, callback) { 
 
        let path = this.collectionPath + '/' + recordId; 
 
        let headers = { 
            Authorization: await this.getAuthorizationHeader(), 
             
        }; 
        let queryStringParameters = {}; 
        let options = { headers: headers, queryStringParameters: queryStringParameters }; 
 
        this.makeGetApiCall(this.apiName, path, options).then(callback);
    } 
 
 
    async add(payload, callback) { 
        let path = '/feedback/'; 
 
        let headers = { 
            "Content-Type": "application/json", 
            Authorization: await this.getAuthorizationHeader() 
        }; 
 
        let options = { 
            body: payload, 
            headers: headers 
        }; 
 return new Promise((resolve, reject) => {

   this.makePostApiCall(this.apiName, path, options)

      .then(response => {
         if (response.status) {

          return resolve(response);

        } else {

          return reject(response.status, response.json());

        }
      });
  });

    } 
 
    async update(recordId, payload, callback) { 
        let path = this.collectionPath + '/' + recordId; 
 
        let headers = { 
            "Content-Type": "application/json", 
            Authorization: await this.getAuthorizationHeader() 
        }; 
 
        let options = { 
            body: payload, 
            headers: headers 
        }; 
 
        this.makePutApiCall(this.apiName, path, options).then(callback); 
    } 
 
    async deleteRecord(recordId, callback) { 
 
        let path = this.collectionPath + '/' + recordId; 
 
        let headers = { 
            Authorization: await this.getAuthorizationHeader() 
        }; 
 
        let queryStringParameters = {}; 
        let options = { headers: headers, queryStringParameters: queryStringParameters }; 
 
        this.makeDeleteApiCall(this.apiName, path, options).then(callback); 
 
    } 
 
    async makeGetApiCall(apiName, path, options) { 
        logger.debug(`making GET call to ${apiName} api with path: ${path}`); 
 
        try { 
            return await API.get(apiName, path, options); 
        } 
        catch (e) { 
            logger.error(e); 
        } 
 
    } 
 
    async makePostApiCall(apiName, path, options) { 
        logger.debug(`making POST call to ${apiName} api with path: ${path}`); 
 
        try { 
            return await API.post(apiName, path, options); 
        } 
        catch (e) { 
            logger.error(e); 
        } 
 
    } 
 
    async makePutApiCall(apiName, path, options) { 
        logger.debug(`making PUT call to ${apiName} api with path: ${path}`); 
 
        try { 
            return await API.put(apiName, path, options); 
        } 
        catch (e) { 
            logger.error(e); 
        } 
 
    } 
 
    async makeDeleteApiCall(apiName, path, options) { 
        logger.debug(`making DELETE call to ${apiName} api with path: ${path}`); 
 
        try { 
            return await API.del(apiName, path, options); 
        } 
        catch (e) { 
            logger.error(e); 
        } 
 
    } 
 
 
} 
 
export default BaseService; 