
const DropdownDates = {
    default: [
            

        { value: "06", year:"2019",label: 'Jun-2019', default: 1  },
        { value: "05", year:"2019",label: 'May-2019',  default: 0},
        { value: "04", year:"2019",label: 'Apr-2019',  default: 0},
        { value: "03", year:"2019",label: 'Mar-2019', default: 0 },
        { value: "02", year:"2019",label: 'Feb-2019', default: 0  },
        { value: "01",year:"2019", label: 'Jan-2019', default: 0  },
        { value: "12", year:"2018",label: 'Dec-2018', default: 0  },
        { value: "11",year:"2018", label: 'Nov-2018', default: 0  },
        { value: "10",year:"2018", label: 'Oct-2018', default: 0  },
        { value: "09",year:"2018", label: 'Sept-2018', default: 0  }

    ], 
    marketplace: [
        {value: "04", year:"2019",label: 'Apr-2019', default: 1},
        {value: "03", year:"2019",label: 'Mar-2019', default: 0 },
        {value: "02", year:"2019",label: 'Feb-2019', default: 0 },
        {value: "01", year:"2019",label: 'Jan-2019', default: 0 }
    ]
      
};


export default DropdownDates;