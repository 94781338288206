import React from 'react';
 import {Modal,Container, ModalHeader, ModalFooter, ModalBody,Button} from 'reactstrap'; 

class Popup extends React.Component { 
    
    constructor(props) {
        super(props)
        if(props.modal)
        this.state = {
            modal: props.modal,
            isOpen: false
        }
        
        //this.closeModal = this.closeModal.bind(this)
    }
    
    closeModal() {
        this.setState({modal: false})
    }
    
    showModal() {
        this.setState({modal: true})
    }
    
    componentDidMount() {
        this.showModal()
    }
    
    componentDidUpdate() {
        
    }
    
    render() {
        
        return(
            <Container>
                <Modal isOpen={this.state.modal} toggle={this.closeModal.bind(this, 'modal')}>
                    <ModalHeader>{this.props.title}</ModalHeader>
                    <ModalBody>
                        {this.props.message}
                    </ModalBody>
                    <ModalFooter>
                        <Button id="ok" className='primary' onClick={this.closeModal.bind(this,'modal')}>OK</Button>
                    </ModalFooter>
                </Modal>
            </Container>
            )
    }
  
}

export default Popup