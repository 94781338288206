import React from 'react';
import { ListGroup, ListGroupItem, Row, Col } from 'reactstrap';
import { Logger } from 'aws-amplify';

const logger = new Logger('settings-page');

class SettingsPage extends React.Component {

    constructor(props) {
        super(props)
        logger.debug('CONSTRUCTOR PROPS: ', props);
    }

    render() {

        let { changeThemeHanlder, theme } = this.props;
        let themeValue = theme === 'dark' ? "checked" : '';

        return (
            <ListGroup>    

                <ListGroupItem>
                <Row className="my-4">
                    <Col className="text-center">
                      <div className="page-title">Settings</div>
                    </Col>
                </Row>
                <Row>
                    <Col><strong>Strart Page:</strong></Col>
                    <Col>Overview</Col>
                </Row>
                </ListGroupItem>

                <ListGroupItem>
                <Row>
                    <Col><strong>Default Reporting Period:</strong></Col>
                    <Col>3 Months</Col>
                </Row>
                </ListGroupItem>

                <ListGroupItem>
                <Row>
                <Col><strong>Dark Theme:</strong></Col>
                <Col>
                <div className="custom-switch">
                    <input type="checkbox" className="custom-control-input" id="customSwitch2" checked={themeValue} onChange={changeThemeHanlder}/>
                    <label className="custom-control-label" htmlFor="customSwitch2"></label>
                </div>
                </Col>
                </Row>
                </ListGroupItem>
            
            </ListGroup>
        );
    }


}

export default SettingsPage;
