let analytics = {
  // OPTIONAL - disable Analytics if true
  disabled: process.env.REACT_APP_ANALYTICS_DISABLED === 'true',
  // OPTIONAL - Allow recording session events. Default is true.
  autoSessionRecord: true,

  AWSPinpoint: {

    // OPTIONAL -  Amazon Pinpoint App Client ID
    appId: process.env.REACT_APP_PINPOINT_PROJECT_ID,
    // OPTIONAL -  Amazon service region
    region: process.env.REACT_APP_COGNITO_REGION,

    endpoint: {
      userAttributes: {}
    },

    // Buffer settings used for reporting analytics events.
    // OPTIONAL - The buffer size for events in number of items.
    bufferSize: 1000,

    // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
    flushInterval: 5000, // 5s 

    // OPTIONAL - The number of events to be deleted from the buffer when flushed.
    flushSize: 100,

    // OPTIONAL - The limit for failed recording retries.
    resendLimit: 5

  }
};

export default analytics;
