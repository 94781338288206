import BaseService from 'services/BaseService';

import LinkHelper from 'util/LinkHelper';

const linkhelper = new LinkHelper();


class InsightsMonthlyService extends BaseService {


  constructor() {

    let apiName = "InsightsMonthlyDataAPI";

    let tenantApiName = "TenantManagementServiceAPI";

    let collectionPath = "/monthly";

    super(apiName, tenantApiName, collectionPath);
  }


  async getteanantid() {

    if (linkhelper.getCurAuthTenandid() !== null) {
      return {
        "pac-selected-tenant-id": linkhelper.getCurAuthTenandid()
      };
    }

    return null;
  }

  async fetchDateRange(tenantId, isConnectivity) {
    let path = isConnectivity ? "/metrics/timespans?qoeFlag=true" : "/metrics/timespans";
    let headers;

    await this.getteanantid().then(response => {
      headers = response;
    });

    let options = { headers: headers };

    return this.makeGetApiCall(this.apiName, path, options);
  }

  // async fetchMetrics(tenantId, year, month) {

  //   let path = "/v1" + "/monthly-metrics/" + year + "-" + month;
  //   let headers;
  //   await this.getteanantid().then(response => {
  //     headers = response;
  //   });

  //   let options = { headers: headers };

  //   return this.makeGetApiCall(this.apiName, path, options);
  // }

  async fetchMetrics(year, month, page) {
    let media = page ? `/${page}` : '';
    let path = `/metrics/${year}-${month}${media}`

    let headers;
    await this.getteanantid().then(response => {
      headers = response;
    });

    let options = { headers: headers };

    return this.makeGetApiCall(this.apiName, path, options);
  }

  async fetchSeatbackMetrics(year, month) {
    let path = "/metrics/" + year + "-" + month + "/" + "seatback";

    let headers;
    await this.getteanantid().then(response => {
      headers = response;
    });

    let options = { headers: headers };

    return this.makeGetApiCall(this.apiName, path, options);
  }

  async fetchPortalbackMetrics(year, month) {
    let path = "/metrics/" + year + "-" + month + "/" + "portal";

    let headers;
    await this.getteanantid().then(response => {
      headers = response;
    });

    let options = { headers: headers };

    return this.makeGetApiCall(this.apiName, path, options);
  }

  async fetchMonitoringMetrics(year, month) {
    let path = `/monitoring/data/${year}-${month}`;

    let headers;
    await this.getteanantid().then(response => {
      headers = response;
    });

    let options = { headers: headers };

    return this.makeGetApiCall(this.apiName, path, options);
  }

  async addfeedback(payload) {
    let path = "/api" + "/feedback/";

    let headers;
    await this.getteanantid().then(response => {
      headers = response;
    });

    let options = { headers: headers };
    return this.add(payload, this.apiName, path, options);
  }

  async fetchWorkbenchMetrics(category, year, month) {
    let path = `/metrics/${year}-${month}/${category}/workbench`;

    let headers;
    await this.getteanantid().then(response => {
      headers = response;
    });

    let options = { headers: headers };

    return this.makeGetApiCall(this.apiName, path, options);
  }

  async fetchMediaDetails(media, mediaName, year, month) {
    let path = `/metrics/${year}-${month}/${media}/${mediaName}`

    let headers;
    await this.getteanantid().then(response => {
      headers = response
    })

    let options = { headers: headers }

    return this.makeGetApiCall(this.apiName, path, options)
  }

  async fetchMediaOptimization(media, year, month) {
    let path = `/myinsights/optimization/${year}-${month}/${media}`
    let headers, options;
    await this.getteanantid().then(response => {
      headers = response;
    })

    options = { headers: headers }

    return this.makeGetApiCall(this.apiName, path, options)
  }

}

export default InsightsMonthlyService;
