import React from 'react';
import { Redirect } from 'react-router-dom';
import { Auth, Logger } from 'aws-amplify';

class SignInPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectToReferrer: false,
            returnPath: process.env.REACT_APP_LOGINAPP_URL
        };
    }


    render() {

        let { redirectToReferrer, returnPath } = this.state;

        if (redirectToReferrer === true) {
            return (<Redirect to={returnPath} />);
        }

    }

}

export default SignInPage;
